import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import bytesFormatter from 'utils/formatter/bytes';
import moment from 'moment';
import { Radio } from '@ubnt/ui-components';

export const COLUMNS = [
  {
    id: 'select',
    label: '',
    // eslint-disable-next-line react/prop-types
    renderCell: ({ selected }) => <Radio checked={selected} />,
    minWidth: 2,
  },
  {
    id: 'controller_name',
    renderLabel: <FormattedMessage id="SETUP_NAME" />,
    sortable: true,
    minWidth: 25,
  },
  {
    id: 'time',
    renderCell: (row, column) => (
      <FormattedDate
        value={moment(row[column.id])}
        year="2-digit"
        month="2-digit"
        day="2-digit"
        hour="2-digit"
        minute="2-digit"
        second="2-digit"
      />
    ),
    renderLabel: <FormattedMessage id="SETUP_TABLE_BACKUPS_HEADER_DATE" />,
    sortable: true,
    minWidth: 25,
  },
  {
    id: 'size',
    renderCell: (row, column) => bytesFormatter(row[column.id]),
    renderLabel: <FormattedMessage id="SETUP_TABLE_BACKUPS_HEADER_SIZE" />,
    sortable: true,
    minWidth: 15,
  },
];
